import { isNil, omit } from 'ramda';
import * as yup from 'yup';
import { parseISO } from 'date-fns';

import { ValidationError } from 'src/enums/Validation';
import { AccountType } from 'src/enums/AccountType';
import { Organization } from 'src/types/resources/Organization';
import { DocumentFile } from 'src/forms/talents/documents';
import { replaceNullKeys } from 'src/utils/keysConverter';
import { getMaxFieldLengthErrorMessage } from 'src/utils/validation';
import { convertDateToServerFormat } from 'src/utils/date';
import { User } from 'src/types/resources/User';
import { UserRole } from 'src/enums/UserRole';

export type CreateOrganizationFormData = {
  accountType: AccountType;
  name: string;
  website: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  note: string;
  logo?: DocumentFile | null;
  licenseStartDate?: Date | null;
  licenseEndDate?: Date | null;
  contractExecutionDate: Date | null;
  isEngagementRequiresPurchaseOrder?: boolean;
  isW2Type?: boolean;
  is1099Type?: boolean;
};

export type CreateOrganizationFormDataToSubmit = Omit<
  CreateOrganizationFormData,
  'logo' | 'licenseStartDate' | 'licenseEndDate' | 'contractExecutionDate'
> & {
  logo?: File | null;
  licenseStartDate?: string;
  licenseEndDate?: string;
  contractExecutionDate?: string;
};

export type CreateOrganizationFormIncludeFields = ('licenseDate' | 'purchaseOrders')[];

const defaulDateValidation = yup
  .date()
  .min(new Date(2021, 0, 1), 'License year should be later than 2020')
  .nullable()
  .typeError(ValidationError.date)
  .default(null);

export const getValidationFields = (params: {
  organization: Organization;
  featureShowOrgBrrPricingUpdate: boolean;
  featureUseW2EngagementTypeForOrg: boolean;
  userRole: User['role'];
}) => {
  const { organization, featureShowOrgBrrPricingUpdate, featureUseW2EngagementTypeForOrg, userRole } = params;
  return {
    accountType: yup
      .mixed()
      .oneOf(Object.values(AccountType))
      .required(ValidationError.default)
      .default(AccountType.prospect)
      .label('Account Type'),
    name: yup.string().required(ValidationError.default).default('').label('Name'),
    licenseStartDate: yup.lazy((value, schemaContext) => {
      if (organization) {
        return defaulDateValidation;
      }

      if (!featureShowOrgBrrPricingUpdate && schemaContext.parent.accountType === AccountType.client) {
        return defaulDateValidation;
      }

      if (
        featureShowOrgBrrPricingUpdate &&
        (schemaContext.parent.accountType === AccountType.test ||
          schemaContext.parent.accountType === AccountType.client)
      ) {
        return defaulDateValidation.required(ValidationError.default);
      }

      return defaulDateValidation;
    }),
    licenseEndDate: yup.lazy((value, schemaContext) => {
      if (organization) {
        return defaulDateValidation.min(yup.ref('licenseStartDate'), ValidationError.dateEndBeforeDateStart);
      }

      if (!featureShowOrgBrrPricingUpdate && schemaContext.parent.accountType === AccountType.client) {
        return defaulDateValidation.min(yup.ref('licenseStartDate'), ValidationError.dateEndBeforeDateStart);
      }

      if (
        featureShowOrgBrrPricingUpdate &&
        (schemaContext.parent.accountType === AccountType.test ||
          schemaContext.parent.accountType === AccountType.client)
      ) {
        return defaulDateValidation
          .min(yup.ref('licenseStartDate'), ValidationError.dateEndBeforeDateStart)
          .required(ValidationError.default);
      }
      return defaulDateValidation.min(yup.ref('licenseStartDate'), ValidationError.dateEndBeforeDateStart);
    }),
    contractExecutionDate: yup
      .date()
      .default(null)
      .typeError(ValidationError.date)
      .nullable()
      .when('accountType', {
        is: (accountType: AccountType) => accountType === AccountType.client,
        then: schema => (userRole === UserRole.SuperAdmin ? schema.required(ValidationError.default) : schema),
      }),
    website: yup.string().url(ValidationError.url).default('').label('Website'),
    address1: yup
      .string()
      .nullable()
      .required(ValidationError.default)
      .max(300, getMaxFieldLengthErrorMessage(300))
      .default('')
      .label('Address Line 1'),
    address2: yup.string().max(300, getMaxFieldLengthErrorMessage(300)).default('').label('Address Line 2'),
    city: yup
      .string()
      .required(ValidationError.default)
      .max(150, getMaxFieldLengthErrorMessage(150))
      .default('')
      .label('City'),
    state: yup
      .string()
      .required(ValidationError.default)
      .max(150, getMaxFieldLengthErrorMessage(150))
      .default('')
      .label('State'),
    zipCode: yup
      .string()
      .required(ValidationError.default)
      .max(10, getMaxFieldLengthErrorMessage(10))
      .default('')
      .label('ZIP'),
    note: yup.string().default('').max(500, getMaxFieldLengthErrorMessage(500)).label('Note'),
    isEngagementRequiresPurchaseOrder: yup.boolean(),
    isW2Type: yup
      .boolean()
      .test('only-one-eng-type-checked', 'Only one engagement type can be selected', (isW2Type, testContext) => {
        const { is1099Type } = testContext.parent;
        if (!featureUseW2EngagementTypeForOrg || userRole !== UserRole.SuperAdmin) {
          return true;
        }
        if (is1099Type && isW2Type) {
          return testContext.createError({ message: 'Only one of 1099 or W2 types can be selected' });
        }
        if (!is1099Type && !isW2Type) {
          return testContext.createError({ message: 'At least one engagement type must be selected' });
        }
        return true;
      }),
    is1099Type: yup.boolean(),
  };
};

export const getValidationSchema = (params: {
  organization: Organization;
  featureShowOrgBrrPricingUpdate: boolean;
  featureUseW2EngagementTypeForOrg: boolean;
  userRole: User['role'];
}) => yup.object(getValidationFields(params)).shape({});

export const initialValues = (params: {
  organization: Organization;
  featureShowOrgBrrPricingUpdate: boolean;
  featureUseW2EngagementTypeForOrg: boolean;
  userRole: User['role'];
}): CreateOrganizationFormData => {
  const { organization } = params;
  const validationSchema = getValidationSchema(params);

  if (isNil(organization)) {
    return validationSchema.getDefault();
  }

  const defaultValues: CreateOrganizationFormData = {
    ...omit(['logo', 'licenseStartDate', 'licenseEndDate'], replaceNullKeys<Organization>(organization, '')),
    licenseEndDate: organization.licenseEndDate ? parseISO(organization.licenseEndDate) : null,
    licenseStartDate: organization.licenseStartDate ? parseISO(organization.licenseStartDate) : null,
    contractExecutionDate: organization.contractExecutionDate ? parseISO(organization.contractExecutionDate) : null,
  };

  return defaultValues;
};

export const attributesToSubmit = (
  values: CreateOrganizationFormData,
  includeFields: CreateOrganizationFormIncludeFields,
): CreateOrganizationFormDataToSubmit => {
  const result: CreateOrganizationFormDataToSubmit = {
    ...omit(['logo', 'licenseStartDate', 'licenseEndDate', 'purchaseOrders', 'contractExecutionDate'], values),
  };

  if (includeFields.includes('licenseDate')) {
    result.licenseEndDate = values.licenseEndDate ? convertDateToServerFormat(values.licenseEndDate) : null;
    result.licenseStartDate = values.licenseStartDate ? convertDateToServerFormat(values.licenseStartDate) : null;
  }

  if (includeFields.includes('purchaseOrders')) {
    result.isEngagementRequiresPurchaseOrder = values.isEngagementRequiresPurchaseOrder;
  }

  if (values.accountType === AccountType.client) {
    result.contractExecutionDate = values.contractExecutionDate
      ? convertDateToServerFormat(values.contractExecutionDate)
      : null;
  }

  return result;
};
